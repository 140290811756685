//import * as React from 'react';
import React, { useState, useEffect } from "react";
import Button from '../components/Button';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import FormContent from "./FormContent";

export default function Form(props) {

	const { set, typeDialog, setTypeDialog, isMenuOption, currentUrl } = props;
	const [open, setOpen] = useState(false);
	const [disabledButton, setDisabledButton] = React.useState(false);

  const handleClickOpen = (type) => {
    // setPrivacidad(false)
    // setTerminos(false)
    // setDataForm({
    //   nombre: "",
    //   apellidoPa: "",
    //   apellidoMa: "",
    //   alias: "",
    //   numTelefono: '',
    //   email: '',
    //   CodigoP: '',
    //   colonia: '',
    //   municipio: [],
    //   calle: '',
    //   numExt: '',
    //   numInt: '0',
    //   distribuidor: "",
    //   edad: ""

    // })
    if (type === 'aparta') {
      return window.location.href = `${process.env.REACT_APP_URL}/aparta`;
    }
    setTypeDialog(type)
    setOpen(true);
  };

  const handleClose = () => {
    //setValidar(false)
    setOpen(false);
  };
  
  const hasStock = async () => {
    
    try {
      console.log(process.env.REACT_APP_BACKEND_URL);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/status_stock`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      
      console.log("response", result.status);
	  setDisabledButton(result.status);
      return result.status;
    } catch (err) {

      console.log(err);
	  setDisabledButton(false);
	  return false;
    }
  }
  
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const setFinalState = () => {
    setOpen(false)
    set(true)
  }

  
  //hasStock();

  return (
	
    <>
      {isMenuOption ? (
        <Link sx={{ fontSize: 12, fontFamily: "mmcregular", color: "white" }}
        href="#" onClick={() => {
          handleClickOpen("aparta");
        }}>APÁRTALA AQUÍ</Link>
      ) : (
      <div className="" sx={{ alignItems: "center", textAlign:"center"}}>
        
        
        

       

            <>
            

            <Grid container spacing={2}
              marginTop={16}
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              md={8}
              lg={8}
              >
                <Button
                  onClick={() => {
                    handleClickOpen("aparta");
                  }}
                  variant="contained"
                  size= "medium"
                  color="primary"
          //disabled = { !disabledButton  }
                  style={{
                    width: '290px',
                    height: '65px',
                    background: "#cf0303",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    marginBottom:"32px",
          //display: disabledButton ? 'block' : 'none'
                  }}
                >
                  APÁRTALA
                </Button>
                <Button
                  onClick={() => {
                    handleClickOpen("info");
                  }}
                  variant="contained"
                  size= "medium"
                  color="primary"
          //disabled = { !disabledButton  }
                  style={{
                    width: '290px',
                    height: '65px',
                    background: "#ffffff",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    color: "#000000",
                    marginBottom:"32px",
          //display: disabledButton ? 'block' : 'none'
                  }}
                >
                  SUSCRÍBETE
                </Button>
              </Grid>              
            </Grid>
            </>
					


      </div>
      )}
    <Dialog
			fullWidth={true}
			maxWidth='md'
			open={open}
			onClose={handleClose}>
			<DialogTitle style={{
          fontFamily: "mmcoffice",
          fontSize: 25,
          lineHeight:"1em",
          marginTop: "15px",

        }}>
          <center>
            {typeDialog == "aparta" ? "APÁRTALA AQUÍ" : "Más Información"}
          </center>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}

            sx={{
              position: 'absolute',
              right: 8,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
			<DialogContent>
          <FormContent typeDialog={typeDialog} currentUrl={currentUrl} finalState={setFinalState}></FormContent>
			</DialogContent>

		  </Dialog>
    </>


  )
}
