import * as React from "react";
import AppAppBar from '../../modules/views/AppAppBar';
import AppFooter from '../../modules/views/AppFooter';
import FormContent from "./FormContent";
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";
import withRoot from "../withRoot";
import Endsistem from '../views/endsistem'

function Aparta() {

  const [fin, setfin] = React.useState(false);
  const [typeDialog, setTypeDialog] = React.useState('aparta');
  const [currentUrl, setCurrentUrl] = React.useState(false);

  React.useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const setFinalState = () => {
    setfin(true);
  }

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  return (
    <React.Fragment>
      <AppAppBar set={setfin} fin={fin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} isMenuOption={true} url={currentUrl} isApartaUrl={true}/>
      {fin == false ? (
        <>
          {/* <ProductHero set={setfin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} url={currentUrl}/>
          <ProductValues />
          <ProductCategories set={setfin} setTypeDialog={setTypeDialog} typeDialog={typeDialog} url={currentUrl}/> */}
          <section id="apartala1" style={{ marginTop: "-80px" }}>
            <div class="section-content has-diagonal" style={{ marginTop: '100px', marginBottom: "100px" }}>
              <Grid container spacing={2} style={{ marginTop: +isMobile()?  '60px' :"120px", marginBottom: +isMobile()?  '60px' :"120px", placeContent: "space-around" }}>
                <Grid item xs={10} md={12} lg={10} sx={{ alignItems: "center"}}>
                  <Typography style={{
                    fontSize: "clamp(3em, 8vw, 3rem)",
                    marginTop: 0,
                    color: "black",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "10px",
                    fontFamily: "mmcoffice",
                    letterSpacing: "4",
                    lineHeight:"1em",
                    fontWeight:"bold"
                  }} align="center" component="h1" color="white">
                    APÁRTA AQUÍ TU L200 GSR
                  </Typography>
                  <FormContent typeDialog={typeDialog} currentUrl={currentUrl} finalState={setFinalState}></FormContent>
                </Grid>
              </Grid>
            </div>
          </section>
        </>
      ) : <Endsistem set={setfin} fin={fin} typeDialog={typeDialog} isApartaUrl={true}/>}
      <AppFooter sx={{ zINdex: "999" }} />
    </React.Fragment >
  );
}

export default withRoot(Aparta);
