import React, { useState, useEffect } from "react";
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Button from '../components/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import axios from "axios";

export default function FormContent(props) {

  const { typeDialog, currentUrl, finalState } = props;
  const [privacidad, setPrivacidad] = React.useState(false);
	const [terminos, setTerminos] = React.useState(false);
  const [validar, setValidar] = React.useState(false);
	const [datadealers, setDataDealers] = useState([]);
  const [dataOthers, setDataOthers] = useState([]);
	const [valueGenero, setValueGenero] = React.useState('FEMENINO');
	const [valueTiempo, setValueTiempo] = React.useState('0 A 3 MESES');
	const [asentamiento, setAsentamiento] = useState([]);
	const [openAler, setOpenAlert] = React.useState(false);
  const [sending, setSending] = React.useState(false);
	const [dataForm, setDataForm] = useState({
		nombre: "",
		apellidoPa: "",
		apellidoMa: "",
    alias: "",
		numTelefono: '',
		email: '',
		CodigoP: '',
		colonia: '',
		municipio: [],
		calle: '',
		numExt: '',
		numInt: '0',
		distribuidor: "",
    edad: "",
    color: "",
	});

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
    
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
    
      if (isScriptExist && callback) callback();
    }
    
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  const formattedDealers = (state, name) => {
    const abrev = {
      "AGUASCALIENTES": "AGS",
      "BAJA CALIFORNIA": "BC",
      "BAJA CALIFORNIA SUR": "BCS",
      "CAMPECHE": "CAMP",
      "COAHUILA": "COAH",
      "COLIMA": "COL",
      "CHIAPAS": "CHIS",
      "CHIHUAHUA": "CHIH",
      "CIUDAD DE MÉXICO": "CDMX",
      "DURANGO": "DGO",
      "GUANAJUATO": "GTO",
      "GUERRERO": "GRO",
      "HIDALGO": "HGO",
      "JALISCO": "JAL",
      "ESTADO DE MÉXICO": "MEX",
      "MICHOACÁN": "MICH",
      "MORELOS": "MOR",
      "NAYARIT": "NAY",
      "NUEVO LEÓN": "NL",
      "OAXACA": "OAX",
      "PUEBLA": "PUE",
      "QUERÉTARO": "QRO",
      "QUINTANA ROO": "QROO",
      "SAN LUIS POTOSÍ": "SLP",
      "SINALOA": "SIN",
      "SONORA": "SON",
      "TABASCO": "TAB",
      "TAMAULIPAS": "TAMPS",
      "TLAXCALA": "TLAX",
      "VERACRUZ": "VER",
      "YUCATÁN": "YUC",
      "ZACATECAS": "ZAC",
    }
    return `${abrev[state] ? abrev[state] : state} - ${removeDealerCode(name)}`;
  };

  const removeDealerCode = (dealer) => {
    const stringArray = dealer.split(" ");
    stringArray.shift();
    return stringArray.join(" ");
  };

  const handleChangeGenero = (event) => {
    setValueGenero(event.target.value);
  };

  const handleChangeTiempo = (event) => {
    setValueTiempo(event.target.value);
  };

  const handleChangePrivacidad = (event) => {
    setPrivacidad(event.target.checked)
  };

  const handleChangeTerminos = (event) => {
    setTerminos(event.target.checked)
  };

  const validarCodigo = async () => {
    setDataDealers([]);
    setDataOthers([]);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/search_cp/${dataForm.CodigoP}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      handleDistribuidor(result.data_location.estado)
      //console.log("response", result);
      setAsentamiento(result.data_location.asentamiento)
      setDataForm({
        ...dataForm,
        municipio: result.data_location.municipio

      }
      )
    } catch (err) {
      console.log(err);
    }
  }

  const handleDistribuidor = (estado) => {
    //let nameestado = removeAccents(estado)
    //const procesado = nameestado.replace(/\s+/g, '')  // > "Textodeejemplo"

    let url = `${process.env.REACT_APP_BACKEND_URL}/api/get_dealers/${estado.toUpperCase()}`;
    const config = {
      headers: {
        Accept: " application/json",

      },
    };

    axios
      .get(url, null, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
		//console.log("response dist", response)
        if (response.data.error) {
          setDataDealers([]);
          setDataOthers([]);
          alert(response.data.error.avaible)
        } else {
          setDataDealers(response.data.data_dealers);
          setDataOthers(response.data.data_other);
        }
      })
      .catch((error) => {
        // Error 😨
        alert("error")
        console.log(error);
      });

  }
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }
  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };


  function handleInput(event) {
    //console.log("event", event.target.name)
    if (event.target.name == "numTelefono" || event.target.name == "CodigoP") {
      let resultado = removerCaracteres(event);
      setDataForm({
        ...dataForm,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value,
    });
  }

  const handleChange = (event) => {

    setDataForm({
      ...dataForm,
      colonia: event.target.value,
    });
  };

  const handelChangeDis = (event) => {
    //console.log("VALUE", event.target.value)
    setDataForm({
      ...dataForm,
      distribuidor: event.target.value,
    });
  };

  const handelChangeEdad = (event) => {
    //console.log("VALUE", event.target.value)
    setDataForm({
      ...dataForm,
      edad: event.target.value,
    });
  };

  const handelChangeColor = (event) => {
    //console.log("VALUE", event.target.value)
    setDataForm({
      ...dataForm,
      color: event.target.value,
    });
  };				   

  //console.log("data122", dataForm)

  const handleLeads = () => {
    setSending(true);
    let data = {}
    window.scrollTo(0, 0)

    let url = ``
	
	
	
	
	// gtag('event', 'registro_completo_tag', {
	  // 'correo': dataForm.email, 'edad': dataForm.edad, 'distribuidor': dataForm.distribuidor, 'genero': valueGenero
	// });

    if (typeDialog == "info") {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/create_lead`;
		// window.dataLayer.push({
		// 'event': 'registro_completo_layer',
		// 'correo': dataForm.email,
		// 'edad': dataForm.edad,
		// 'distribuidor': dataForm.distribuidor,
		// 'genero': valueGenero,
		// 'tiempocompra': 'NA'
		// });
      data = {
        "lead_distribuidor": dataForm.distribuidor,
        "lead_nombre": dataForm.nombre,
        "lead_apellido_paterno": dataForm.apellidoPa,
        "lead_apellido_materno": dataForm.apellidoMa,
        "lead_alias": dataForm.alias,
        "lead_num_cel": dataForm.numTelefono,
        "lead_email": dataForm.email,
        "lead_genero": valueGenero,
        "lead_estimado_compra": valueTiempo,
        "lead_edad": dataForm.edad,
        "lead_aviso_privacidad": "on",
        "lead_terminos_condiciones": "on",
        "lead_url": currentUrl
      }
    }
    else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/create_presale`;
		// window.dataLayer.push({
		// 'event': 'registro_completo_layer',
		// 'correo': dataForm.email,
		// 'edad': dataForm.edad,
		// 'distribuidor': dataForm.distribuidor,
		// 'genero': 'NA',
		// 'tiempocompra': 'NA'
		// });
      data =
      {
        "presale_distribuidor": dataForm.distribuidor,
        "presale_nombre": dataForm.nombre,
        "presale_apellido_paterno": dataForm.apellidoPa,
        "presale_apellido_materno": dataForm.apellidoMa,
        "presale_alias": dataForm.alias,
        "presale_num_cel": dataForm.numTelefono,
        "presale_email": dataForm.email,
        "presale_codigo_postal": dataForm.CodigoP,
        "presale_colonia": dataForm.colonia,
        "presale_municipio": dataForm.municipio,
        "presale_calle": dataForm.calle,
        "presale_num_int": dataForm.numInt,
        "presale_num_ext": dataForm.numExt,
        "presale_aviso_privacidad": "on",
        "presale_terminos_condiciones": "on",
        "presale_url": currentUrl,
        "presale_color": dataForm.color
      }

    }


    const config = {
      headers: {
        Accept: " application/json",
      },
    };
    //console.log("sdaDATA", data)
    if (disableBotom == true) {
      setValidar(true)
      setOpenAlert(true)
      setSending(false);
    } else {

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then(token => {
          data["g-recaptcha-response"] = token;
          //console.log("g-recaptcha-response");
          //console.log(data);
          axios
          .post(url, data, config, {
            // receive two parameter endpoint url ,form data
          })
          .then((response) => {
            // Respuesta del servidor
            // alert("se envio con exito")
            // setOpen(false)
            // set(true)
            finalState();
            setSending(false);
            window.dataLayer.push({
              'event': 'registro_completo_layer',
              'url': currentUrl,
              'lead_id': typeDialog == "info" ? response.data.lead_data.lead_id : response.data.presale_data.presale_id,
              'lead_tipo' : typeDialog == "info" ? "table_leads" : "table_presales",
              'correo': dataForm.email,
              'edad': dataForm.edad,
              'distribuidor': dataForm.distribuidor,
              'genero': typeDialog == "info" ? valueGenero : 'NA',
              'tiempocompra': 'NA',
              'color': typeDialog == "info" ? "NA" : dataForm.color,
            });
          })
          .catch((error) => {
            // Error 😨
            alert("error")
            console.log(error);
            setSending(false);
          });
        });
      });
    }
  }
  // const handleClosAlert = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpenAlert(false);
  // };


  let disableBotom = true;


  if (typeDialog == "info") {
	  
    if (
      dataForm.nombre.length >= 2 &&
      dataForm.apellidoPa.length >= 2 &&
      //dataForm.alias.length >= 1 &&
      dataForm.numTelefono.length == 10 &&
      dataForm.edad.length >= 1 &&
      dataForm.CodigoP.length >= 4 &&
      dataForm.distribuidor &&
      privacidad == true &&
	  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(dataForm.email) &&
      terminos == true
    ) {
      disableBotom = false

    }
  } else {
    if (
      dataForm.nombre.length >= 2 &&
      dataForm.apellidoPa.length >= 2 &&
      //dataForm.alias.length >= 1 &&
      dataForm.numTelefono.length == 10 &&
      dataForm.CodigoP.length >= 4 &&
      dataForm.distribuidor &&
      dataForm.calle.length >= 2 &&
      dataForm.municipio.length >= 2 &&
      dataForm.numExt.length >= 1 &&
      dataForm.colonia.length >= 2 &&
	  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(dataForm.email) &&
      terminos == true
      &&

      privacidad == true

    ) {
      disableBotom = false

    }

  }
	

  return (
    <>
      <Typography style={{
                    fontSize: "clamp(1em, 5vw, 1.4rem)",
                    //marginTop: "-15px",
                    color: "red",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "40px",
                    marginTop: "0px",
                    fontFamily: "mmcoffice",
                    letterSpacing: "4",
                    lineHeight:"2em"
                  }}>
          <center>
            {typeDialog == "aparta" ? "Con $20,000.00 MXN" : "Déjanos tus datos para ser de los primeros en recibir más información."}
          </center>
        </Typography>
			  {/* <DialogContentText style={{
				fontFamily: "mmcregular",
				fontSize: 20,
			  }}>
				Llena el formulario para poder apartar la nueva Mitsubishi Outlander PHEV
			  </DialogContentText> */}
			  <Grid container spacing={5}>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
          autoFocus
					onChange={handleInput}
					value={dataForm.nombre}
					margin="dense"
					name="nombre"
					label="NOMBRE(S)*"
					fullWidth
					variant="standard"
					error={validar == false || validar == false || dataForm.nombre.length > 2 ? false : true}
					helperText="El campo es requerido*"
          autoComplete="off"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.apellidoPa}
					margin="dense"
					name="apellidoPa"
					label="APELLIDO PATERNO*"
					type="text"
					fullWidth
					variant="standard"
					error={validar == false || dataForm.apellidoPa.length > 2 ? false : true}
					helperText="El campo es requerido*"
          autoComplete="off"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.apellidoMa}

					margin="dense"
					name="apellidoMa"
					label="APELLIDO MATERNO"
					type="text"
					fullWidth
					variant="standard"
          autoComplete="off"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.email}

					margin="dense"
					name="email"
					label="CORREO ELECTRÓNICO*"
					type="email"
					fullWidth
					variant="standard"
					error={validar == false || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(dataForm.email) ? false : true}
					helperText="El campo es requerido*"
          autoComplete="off"
				  />
				</Grid>

				<Grid item xs={12} sm={typeDialog == "aparta" ? 6 : 6} style={{"paddingTop": "10px"}}>
				  <TextField
					inputProps={{ maxLength: 10, style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}
					value={dataForm.numTelefono}

					error={validar == false || dataForm.numTelefono.length > 9 ? false : true}
					helperText="El campo es requerido*"
					margin="dense"
					name="numTelefono"
					label="NÚMERO DE TELÉFONO*"
					type="text"
					fullWidth
					variant="standard"
          autoComplete="off"
				  />
				</Grid>
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={6} style={{"paddingTop": "20px"}}>
					<FormControl variant="standard" fullWidth>
							<InputLabel sx={{ fontSize: 20, fontFamily: "mmcregular", }} id="demo-simple-select-standard-label" error={validar == false || dataForm.edad.length > 1 ? false : true}>EDAD</InputLabel>
								<Select
								  labelId="demo-simple-select-standard-label"
								  id="demo-simple-select-standard"
								  value={dataForm.edad}
								  onChange={handelChangeEdad}
								  label="EDAD"
                  error={validar == false || dataForm.edad.length > 1 ? false : true}
								>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"18 a 24 años"}>{"18 a 24 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"25 a 31 años"}>{"25 a 31 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"32 a 38 años"}>{"32 a 38 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"39 a 45 años"}>{"39 a 45 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"46 a 52 años"}>{"46 a 52 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"53 a 59 años"}>{"53 a 59 años"}</MenuItem>
									<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"60 o más"}>{"60 o más"}</MenuItem>
								</Select>
								 
								  
								  
							   
										
								<FormHelperText error={validar == false || dataForm.edad.length > 1 ? false : true}>El campo es requerido*</FormHelperText>
							  </FormControl>

				  </Grid>
				) : (
          // <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
          //   <TextField
          //   inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
          //   InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
          //   /*autoFocus*/
          //   onChange={handleInput}
          //   value={dataForm.alias}
          //   margin="dense"
          //   name="alias"
          //   label="ALIAS*"
          //   type="text"
          //   fullWidth
          //   variant="standard"
          //   error={validar == false || dataForm.alias.length > 1 ? false : true}
          //   helperText="Elige un alias de como te gustaría que te identifiquemos. El campo es requerido*"
          //   autoComplete="off"
          //   />
          // </Grid>
          <Grid item xs={12} sm={6} style={{"paddingTop": "20px", "textAlign" : "start"}}>
					  <FormControl variant="standard" fullWidth>
							<InputLabel sx={{ fontSize: 20, fontFamily: "mmcregular" }} id="demo-simple-select-standard-label" error={validar == false || dataForm.color.length > 1 ? false : true}>Elige el color de tu L200 GSR</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={dataForm.color}
                onChange={handelChangeColor}
                label="EDAD"
                error={validar == false || dataForm.color.length > 1 ? false : true}
                style={{"paddingTop": "4px"}}
              >
                <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"Yamabuki Orange Metallic"}>{"Yamabuki Orange Metallic"}</MenuItem>
                <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"Jet Black Mica"}>{"Jet Black Mica"}</MenuItem>
                <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"Graphite Gray Metallic"}>{"Graphite Gray Metallic"}</MenuItem>
                <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }} value={"White Diamond"}>{"White Diamond"}</MenuItem>
              </Select>	
              <FormHelperText error={validar == false || dataForm.color.length > 1 ? false : true}>El campo es requerido*</FormHelperText>
            </FormControl>
				  </Grid>
        )}

				<Grid item xs={12} sm={typeDialog == "aparta" ? 6 : 6} style={{"paddingTop": "0px"}}>
				  <TextField
					inputProps={{ maxLength: 5, style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
					/*autoFocus*/
					onChange={handleInput}


					value={dataForm.CodigoP}

					margin="dense"
					name="CodigoP"
					label="CÓDIGO POSTAL*"
					type="text"
					error={validar == false || dataForm.CodigoP.length > 3 ? false : true}
					helperText="El campo es requerido*"
					fullWidth

					onBlur={() => {
					  validarCodigo();
					}}
					variant="standard"
          autoComplete="off"
				  />
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px", "textAlign": "start"}}>
				  <FormControl variant="standard" fullWidth>
					<InputLabel
					  sx={{ fontSize: 20, fontFamily: "mmcregular", }}
					  error={validar == false || dataForm.distribuidor.length > 2 ? false : true}

					  id="demo-simple-select-standard-label">DISTRIBUIDOR</InputLabel>
					<Select
					  labelId="demo-simple-select-label"
					  id="demo-simple-select"
					  input={<NativeSelect sx={{ fontSize: 20, fontFamily: "mmcregular", }} label="Tag" />}

					  value={dataForm.distribuidor}
					  label="DISTRIBUIDOR"
					  error={validar == false || dataForm.distribuidor.length > 2 ? false : true}
					  onChange={handelChangeDis}

					>
					  {datadealers.map((item) => {
						  return (

							<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							  value={item.dealer_code}>{removeDealerCode(item.delaer_name)}</MenuItem>
						  )

					  })}
            <Divider>Otros distribuidores</Divider>
            {dataOthers.map((item) => {
						  return (
							<MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							  value={item.dealer_code}>{formattedDealers(item.dealer_state, item.delaer_name)}</MenuItem>
						  )
					  })}
					</Select>
					<FormHelperText error={validar == false || dataForm.distribuidor.length > 2 ? false : true}>El campo es requerido*</FormHelperText>

				  </FormControl>
				</Grid>
				{typeDialog == "aparta" ? (
				  <><Grid item xs={12} sm={6} style={{"paddingTop": "17px", "textAlign": "start"}}>
					<FormControl variant="standard" fullWidth>
					  <InputLabel
						sx={{ fontSize: 20, fontFamily: "mmcregular", }}
            error={validar == false || dataForm.colonia.length > 2 ? false : true}
						id="demo-simple-select-standard-label">COLONIA</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						input={<NativeSelect sx={{ fontSize: 20, fontFamily: "mmcregular", }} label="Tag" />}
            error={validar == false || dataForm.colonia.length > 2 ? false : true}
						helperText="El campo es requerido*"
						value={dataForm.colonia}
						label="COLONIA"
						onChange={handleChange}
					  >
						{asentamiento.map((item) => (
						  <MenuItem sx={{ fontSize: 20, fontFamily: "mmcregular", }}
							value={item}>{item}</MenuItem>
						))}
					  </Select>
            <FormHelperText error={validar == false || dataForm.colonia.length > 2 ? false : true}>El campo es requerido*</FormHelperText>
					</FormControl>
				  </Grid><Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.municipio}
						margin="dense"
						name="municipio"
						label="MUNICIPIO ALCALDÍA*"
						type="text"
						fullWidth
						error={validar == false || dataForm.municipio.length > 2 ? false : true}
						helperText="El campo es requerido*"
						variant="standard" />
					</Grid><Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.calle}

						margin="dense"
						name="calle"
						label="CALLE*"
						type="text"
						error={validar == false || dataForm.calle.length > 2 ? false : true}
						helperText="El campo es requerido*"
						fullWidth
						variant="standard" />
					</Grid><Grid item xs={6} sm={3} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.numExt}

						margin="dense"
						name="numExt"
						label="NO. EXT.*"
						type="text"
						error={validar == false || dataForm.numExt.length >= 1 ? false : true}
						helperText="El campo es requerido*"
						fullWidth
						variant="standard" />
					</Grid>
					<Grid item xs={6} sm={3} style={{"paddingTop": "10px"}}>
					  <TextField
						inputProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 20, fontFamily: "mmcregular", } }} // font size of input label
						/*autoFocus*/
						onChange={handleInput}
						value={dataForm.numInt}

						margin="dense"
						name="numInt"
						label="NO. INT."
						type="text"
						fullWidth
						variant="standard" />
					</Grid></>
				) : ""}
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					<FormControl>
					  <FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: "black" }} id="demo-radio-buttons-group-label">GÉNERO</FormLabel>
					  <RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						value={valueGenero}
						onChange={handleChangeGenero}
					  >
						<FormControlLabel value="FEMENINO" control={<Radio />} label="Femenino" />
						<FormControlLabel value="MASCULINO" control={<Radio />} label="Masculino" />
						<FormControlLabel value="OTRO" control={<Radio />} label="Otro" />
					  </RadioGroup>
					</FormControl>
				  </Grid>
				) : ""}
				{typeDialog == "info" ? (
				  <Grid item xs={12} sm={6} style={{"paddingTop": "10px"}}>
					<FormControl>
					  <FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: "black" }} id="demo-radio-buttons-group-label">TIEMPO ESTIMADO DE COMPRA</FormLabel>
					  <RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue="0 a 3 meses"
						name="radio-buttons-group"
						value={valueTiempo}
						onChange={handleChangeTiempo}
					  >
						<FormControlLabel value="0 a 3 meses" control={<Radio />} label="0 a 3 meses" />
						<FormControlLabel value="3 a 6 meses" control={<Radio />} label="3 a 6 meses" />
						<FormControlLabel value="Más de 6 meses" control={<Radio />} label="Más de 6 meses" />
						<FormControlLabel value="Sin interés" control={<Radio />} label="Sin interés" />

					  </RadioGroup>
					</FormControl>
				  </Grid>
				) : ""}



				<Grid item xs={12} sm={6} style={{"paddingTop": "10px", "textAlign": "start"}}>
				  <FormControl component="fieldset">
					<FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: validar == false || privacidad == true ? 'false' : '#f44336' }} component="legend">AVISO DE PRIVACIDAD *</FormLabel>
					<FormGroup aria-label="position" row>

					  <FormControlLabel
						sx={{ fontSize: 17, fontFamily: "mmcregular", color: validar == false || privacidad == true ? 'false' : '#f44336' }}
						checked={privacidad}
						onChange={handleChangePrivacidad}
						//value="end"
						control={<Checkbox />}
						label={
						  <Link sx={{ fontSize: 16, fontFamily: "mmcregular", color: validar == false || privacidad == true ? 'false' : '#f44336'}}
							href="https://www.mitsubishi-motors.mx/legal/aviso-de-privacidad" target="_blank">He leído y acepto el aviso de privacidad</Link>} labelPlacement="end"

					  />

					</FormGroup>
				  </FormControl>
				</Grid>
				<Grid item xs={12} sm={6} style={{"paddingTop": "10px", "textAlign": "start"}}>
				  <FormControl component="fieldset">
					<FormLabel sx={{ fontSize: 20, fontFamily: "mmcbold", color: validar == false || terminos == true ? 'false' : '#f44336' }} component="legend">TÉRMINOS  Y CONDICIONES *</FormLabel>
					<FormGroup aria-label="position" row>

					  <FormControlLabel
						sx={{ fontSize: 17, fontFamily: "mmcregular", color: validar == false || terminos == true ? 'false' : '#f44336' }}
						checked={terminos}
						onChange={handleChangeTerminos}

						value="end"
						control={<Checkbox />}
						label={
						  <Link sx={{ fontSize: 16, fontFamily: "mmcregular", color: validar == false || terminos == true ? 'false' : '#f44336' }}
							href="https://www.mitsubishi-motors.mx/legal/terminos-y-condiciones" target="_blank">Estoy de acuerdo con el propósito de uso de datos personales</Link>} labelPlacement="end"
					  />

					</FormGroup>
				  </FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
				  <center>
					<Button
            disabled = {sending}
					  onClick={() => {
						handleLeads();
					  }}
					  variant="contained"
					  size="large"
					  // disabled={disableBotom}
					  color="primary"
					  style={{

						width: '300px',
						height: '65px',
						background: sending ? "#ccc" : "#e32515",
						borderRadius: "15px",
						fontFamily: "mmcbold",
						fontSize: 28,
					  }}
					>
					  ENVIAR
					</Button>
				  </center>

				</Grid>


			  </Grid>
    </>
  )
}